import { sharedGlobalVariableNames } from "../../../build-scripts/shared-globals";

/**
 * Make sure that global variables shared among applications have been defined before starting the application.
 */
const loadingTimeoutMilliseconds = 10_000;

const sharedGlobals: string[] = sharedGlobalVariableNames;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let notLoadedGlobals = sharedGlobals.filter((expectedGlobal) => !(window as any)[expectedGlobal]);
const eventType = "packageImportedEvent";
const loadingTimeout = setTimeout(() => {
    window.removeEventListener(eventType, eventListener);
    const errors = notLoadedGlobals.map((notLoadedGlobal) => `Could not load ${notLoadedGlobal}`);
    alert(`A fatal error occurred: \n ${errors.join("\n")}`);
}, loadingTimeoutMilliseconds);

const eventListener = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    notLoadedGlobals = sharedGlobals.filter((expectedGlobal) => !(window as any)[expectedGlobal]);
    if (notLoadedGlobals.length === 0) {
        window.removeEventListener(eventType, eventListener);
        clearTimeout(loadingTimeout);
        await import("./rootConfig");
    }
};
window.addEventListener(eventType, eventListener);
