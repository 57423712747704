/**
 * Defines the shared packages used by all apps.
 * If the package returns a variableName property, then it will be treated as a global variable.
 * This allows the apps to share a single instance of React, ReactDOM, and possibly other libraries that
 * work better when there's only a single instance of the library.
 *
 * If the variable name is not returned, then the shared package will be added to the global import map
 * so that applications can import it from the same location.
 */
export const sharedPackages = {
    "@mc/application-settings": {
        path: (version, command, portAssignments) => {
            if (command === "serve") {
                const portAssignment =
                    portAssignments && portAssignments.mcApplicationSettings
                        ? portAssignments.mcApplicationSettings
                        : 3000;
                return `http://localhost:${portAssignment}/application-settings/src/index.ts`;
            } else {
                return "/remote-assets/application-settings/index.js";
            }
        },
        variableName: "mcApplicationSettings",
        isModule: true,
        dependsOn: ["react", "flagsmith", "i18next", "react-dom"],
    },
    flagsmith: {
        path: (version) => `https://cdn.jsdelivr.net/npm/flagsmith@${version}/index.js`,
        variableName: "flagsmith",
    },
    i18next: {
        path: (version) => `https://cdn.jsdelivr.net/npm/i18next@${version}/i18next.min.js`,
        variableName: "i18next",
    },
    react: {
        path: (version, command) =>
            command === "serve"
                ? `https://cdn.jsdelivr.net/npm/react@${version}/umd/react.development.js`
                : `https://cdn.jsdelivr.net/npm/react@${version}/umd/react.production.min.js`,
        variableName: "React",
    },
    "react-dom": {
        path: (version, command) =>
            command === "serve"
                ? `https://cdn.jsdelivr.net/npm/react-dom@${version}/umd/react-dom.development.js`
                : `https://cdn.jsdelivr.net/npm/react-dom@${version}/umd/react-dom.production.min.js`,
        variableName: "ReactDOM",
    },
    "single-spa": {
        path: (version) => `https://cdn.jsdelivr.net/npm/single-spa@${version}/lib/es2015/esm/single-spa.min.js`,
        variableName: "singleSpa",
        isModule: true,
    },
    "@emotion/react": {
        path: (version) => `https://cdn.jsdelivr.net/npm/@emotion/react@${version}/dist/emotion-react.umd.min.js`,
        variableName: "emotionReact",
        dependsOn: ["react", "react-dom"],
    },
};
export const sharedGlobalVariableNames = Object.keys(sharedPackages)
    .filter((packageName) => !!sharedPackages[packageName].variableName)
    .map((packageName) => sharedPackages[packageName].variableName);
